* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  /* height: 100vh; */
  background-color: #fff;
}
body {
  font-size: 16px;
  color: #fff;
}
/* .dashboard {
  height: 100vh;
} */
.main {
  display: flex;
  /* height: 100vh; */
}

.title {
  font-size: 3em;
}
.info {
  font-size: 1.1em;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 1.5em;
  color: rgb(224, 224, 224);
}
.btn {
  margin: 0 auto;
  border: none;
  outline: none;
  padding: 0.75em 1.5em;
  font-size: 1em;
  letter-spacing: 1px;
 font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: #e3b04b;
  color: #fff;
}
.btn-white {
  margin: 0 auto;
  outline: none;
  padding: 0.75em 1.5em;
  font-size: 1em;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: #fff;
 border: 1px solid #d1d1d1;
  color: #111;
}
.btn-black {
  margin: 0 auto;
  border: none;
  outline: none;
  padding: 0.75em 2em;
  font-size: 1em;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: #111;
  color: #fff;
}

.btn-white-model {
  margin: 0 auto;
  outline: none;
  font-size: 1em;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: #fff;
 border: 1px solid #d1d1d1;
  color: #111;
}
.btn-black-model {
  margin: 0 auto;
  border: none;
  outline: none;
  font-size: 1em;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: #111;
  color: #fff;
}
.sidebar {
  flex-shrink: 0;
  height: 100%;
  overflow: auto;
}

.sidebar-item {
  padding: 0.75em 1em;
  font-size: 13px;
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
}
.sidebar-item:hover {
  background-color: #f5f1da;
}

.sidebar-title {
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
  color: #757575;
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(90deg);
}
.sidebar-content {
  padding-top: 0.25em;
  font-size: 14px;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  color: #757575;
  font-size: 1em;
  text-decoration: none;
}
.sidebar-item.plain:hover {
  /* text-decoration: underline; */
}
.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}

.dashboard_content {
  width: 100%;
  padding: 1rem;
  color: black;
  background-color: #ffffff;
  
}
