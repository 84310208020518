
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}
.section-header {
    background-color: #ff00ff;
    color: white;
}
.morning {
    background-color: #ffb6c1;
}
.evening {
    background-color: #87cefa;
}
.expense {
    background-color: #ff00ff;
}
.evening-expense {
    background-color: #87cefa;
}
.total-cell {
    background-color: #00ff00;
}
.observation {
    color: red;
    text-align: left;
}
