.s006 {
  /* background: url("../images/Searchs_006.png"); */
  /* background: url("../../../assets/images/1.jpg"); */
  padding: 5px;
  padding-left: 20px;
  margin-top: 15px;
}

.s006 form {
  width: 100%;
  max-width: 790px;
}

.s006 form legend {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  text-align: center;
}

.s006 form .inner-form {
  width: 100%;
}

.s006 form .inner-form .input-field {
  height: 70px;
  width: 100%;
  position: relative;
}
.s006 form .inner-form .input-field input {
  height: 70%;
  width: 100%;
  background: transparent;
  border: 0;
  background: #fff;
  display: block;
  width: 100%;
  padding: 5px 32px 5px 70px;
  font-size: 18px;
  color: #666;
  border-radius: 30px;
}

.s006 form .inner-form .input-field input.placeholder {
  color: #ccc;
  font-size: 18px;
}

.s006 form .inner-form .input-field input:-moz-placeholder {
  color: #ccc;
  font-size: 18px;
}

.s006 form .inner-form .input-field input::-webkit-input-placeholder {
  color: #ccc;
  font-size: 18px;
}

.s006 form .inner-form .input-field input:hover,
.s006 form .inner-form .input-field input:focus {
  box-shadow: none;
  outline: 0;
}

.s006 form .inner-form .input-field .btn-search {
  width: 70px;
  position: absolute;
  left: 0;
  height: 80%;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.s006 form .inner-form .input-field .btn-search:hover,
.s006 form .inner-form .input-field .btn-search:focus {
  outline: 0;
  box-shadow: none;
}

.s006 form .inner-form .input-field .btn-search:hover svg,
.s006 form .inner-form .input-field .btn-search:focus svg {
  fill: #666;
}

.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.left {
  left: 0;
  background-color: #fbfbfb;
}

.right {
  right: 0;
  background-color: red;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.centered img {
  width: 150px;
  border-radius: 50%;
}

.modal.modal-fullscreen .modal-dialog {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  max-width: none;
}

.modal.modal-fullscreen .modal-content {
  height: auto;
  height: 100vh;
  border-radius: 0;
  border: none;
}

.modal.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.custom-card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 1px solid #d5d7da;
  border-radius: 20px;
  box-shadow: 0px;
  height: calc(100% - 30px);
}

.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1029;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.card-border {
  border-left: 5px solid #111;
}
.vertical-line {
  display: inline-block;
  border-left: 1px solid #ccc;
  margin: 0 10px;
  height: 55px;
}

#div-upload-image {
  background-image: url("https://mdbootstrap.com/img/Photos/Others/placeholder-avatar.jpg");
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  transition: 100ms ease-out;
  cursor: pointer;
}
input#upload-image::-webkit-file-upload-button {
  padding: 10px 20px;
  background-color: transparent;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  color: white;
  text-transform: uppercase;
  font-size: x-small;
  transition: 100ms ease-out;
  cursor: pointer;
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 70px;
  height: 50px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 40%;
  width: 84px;
  height: 55px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 0.5rem;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #d7dae3;
  transition: 0.3s ease all;
  z-index: 1;
}

#button-10 .knobs:before,
#button-10 .knobs:after,
#button-10 .knobs span {
  position: absolute;
  top: 4px;
  width: 46%;
  height: 47px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 18px 4px;
  border-radius: 2px;
  transition: 0.3s ease all;
}

#button-10 .knobs:before {
  content: "";
  left: 6px;
  background-color: #000;
}

#button-10 .knobs:after {
  content: "RWF";
  right: 4px;
  color: #4e4e4e;
}

#button-10 .knobs span {
  display: inline-block;
  left: 4px;
  color: #fff;
  z-index: 1;
}

#button-10 .checkbox:checked + .knobs span {
  color: #4e4e4e;
}

#button-10 .checkbox:checked + .knobs:before {
  left: 42px;
  background-color: #cf7500;
}

#button-10 .checkbox:checked + .knobs:after {
  color: #fff;
}

#button-10 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}
.form-control {
  display: block;
  width: 100%;
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
